<!--
 * @Description: 场景安装位置管理
 * @Author: ChenXueLin
 * @Date: 2021-10-15 15:34:13
 * @LastEditTime: 2022-07-15 17:13:05
 * @LastEditors: ChenXueLin
-->

<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      :element-loading-background="loadingBackground"
      v-loading="loading"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="firstClassId">
              <e6-vr-select
                v-model="searchForm.firstClassId"
                :data="firstClassList"
                :loading="selectLoading"
                placeholder="商品分类"
                title="商品分类"
                clearable
                :props="{
                  id: 'firstClassId',
                  label: 'firstClassName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="secondClassId">
              <e6-vr-select
                v-model="searchForm.secondClassId"
                :data="secondClassList"
                placeholder="商品开票名称"
                title="商品开票名称"
                clearable
                :loading="selectLoading"
                :props="{
                  id: 'secondClassId',
                  label: 'secondClassName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="insPlace">
              <el-input
                v-model="searchForm.insPlace"
                placeholder="安装位置"
                title="安装位置"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="isenable">
              <e6-vr-select
                v-model="searchForm.isenable"
                :data="statusList"
                placeholder="状态"
                title="状态"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template v-slot:operateAction>
          <i class="e6-icon-add_line" title="新增" @click="handleAdd"></i>
          <i
            class="e6-icon-check_line1"
            title="启用"
            @click="changeStatus(1)"
          ></i>
          <i
            class="e6-icon-prohibit_line"
            title="禁用"
            @click="changeStatus(0)"
          ></i>
        </template>
      </table-title>
      <!-- 搜索头部 end -->

      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
          :row-key="
            row => {
              return row.id;
            }
          "
          ref="elTable"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="50"></el-table-column>
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
          </el-table-column>
          <el-table-column fixed="right" align="center" label="操作" width="80">
            <template slot-scope="scope">
              <e6-td-operate
                :data="getOperateList()"
                @command="handleOperate($event, scope.row)"
              ></e6-td-operate>
            </template>
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
      <el-dialog
        v-dialogDrag
        title="添加安装位置"
        :visible.sync="addPositionVisible"
        width="500px"
        :before-close="handleClose"
        :close-on-click-modal="false"
        v-loading="dialogLoading"
        :element-loading-background="loadingBackground"
        custom-class="custom-dialog"
      >
        <el-form
          ref="addPlaceForm"
          label-width="100px"
          :model="addPlaceForm"
          :rules="addPlaceFormRules"
        >
          <el-form-item
            class="search-item--1"
            prop="firstClassId"
            label="商品分类"
          >
            <e6-vr-select
              v-model="addPlaceForm.firstClassId"
              :data="firstClassList"
              placeholder="商品分类"
              title="商品分类"
              clearable
              :props="{
                id: 'firstClassId',
                label: 'firstClassName'
              }"
            ></e6-vr-select>
          </el-form-item>
          <el-form-item
            class="search-item--1"
            prop="secondClassId"
            label="商品开票名称"
          >
            <e6-vr-select
              v-model="addPlaceForm.secondClassId"
              :data="dialogSecondClassList"
              placeholder="商品开票名称"
              title="商品开票名称"
              clearable
              :props="{
                id: 'secondClassId',
                label: 'secondClassName'
              }"
            ></e6-vr-select>
          </el-form-item>
          <el-form-item class="search-item--1" prop="insPlace" label="安装位置">
            <el-input
              v-model="addPlaceForm.insPlace"
              placeholder="安装位置"
              title="安装位置"
            ></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="remark">
            <el-input
              v-model.trim="addPlaceForm.remark"
              placeholder="备注"
              maxlength="100"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="dialog-footer" slot="footer">
          <el-button class="cancel" @click="handleClose">取消</el-button>
          <el-button type="primary" @click="handleUpdate">确定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";

import {
  getFirstClassList,
  getSecondClassList,
  getEquInsPlaceList,
  enableEquInsPlaceList,
  disableEquInsPlaceList,
  createPlace,
  updatePlace
} from "@/api";
import { printError } from "@/utils/util";
export default {
  name: "sceneInstallManage",
  components: {},
  data() {
    return {
      total: 0,
      firstClassList: [], //商品分类下拉框
      secondClassList: [], //商品开票名称
      selectLoading: false,
      statusList: [
        { id: 1, label: "启用" },
        { id: 0, label: "禁用" }
      ], //状态
      searchForm: {
        firstClassId: "",
        insPlace: "",
        isenable: "",
        secondClassId: "",
        pageIndex: 1,
        pageSize: 20
      },
      selColumnId: [],
      columnData: [
        {
          fieldName: "id",
          display: true,
          fieldLabel: "场景位置ID",
          width: 120,
          disabled: false,
          fixed: true,
          align: "center"
        },
        {
          fieldName: "firstClassName",
          display: true,
          fieldLabel: "商品分类",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "secondClassName",
          display: true,
          fieldLabel: "商品开票名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "insPlace",
          display: true,
          fieldLabel: "安装位置",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "isenableName",
          display: true,
          fieldLabel: "状态",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "remark",
          display: true,
          fieldLabel: "备注",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createUserName",
          display: true,
          fieldLabel: "创建人",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createdTime",
          display: true,
          fieldLabel: "创建时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: [],
      /*******新增*********/
      addPositionVisible: false,
      addPlaceForm: {
        firstClassId: "",
        insPlace: "",
        secondClassId: "",
        remark: ""
      },
      addPlaceFormRules: {
        firstClassId: [
          {
            required: true,
            message: "请选择商品分类",
            trigger: ["blur", "change"]
          }
        ],
        // secondClassId: [
        //   {
        //     required: true,
        //     message: "请选择商品开票名称",
        //     trigger: ["blur", "change"]
        //   }
        // ],
        insPlace: [
          {
            required: true,
            message: "请输入安装位置",
            trigger: ["blur", "change"]
          }
        ]
      },
      dialogSecondClassList: [], //二级分类
      dialogLoading: false,
      refTable: "elTable",
      queryListAPI: getEquInsPlaceList
    };
  },
  mixins: [base, listPage, listPageReszie],
  computed: {},
  watch: {
    "searchForm.firstClassId": {
      immediate: true,
      handler(val) {
        if (val) {
          this.getSecondClass(val);
        } else {
          this.secondClassList = [];
        }
      }
    },
    "addPlaceForm.firstClassId": {
      immediate: true,
      handler(val) {
        if (val) {
          this.getSecondClass(val, 1);
        } else {
          this.dialogSecondClassList = [];
        }
      }
    }
  },
  created() {
    this.getFirstClass();
    this.queryList();
  },
  mounted() {
    this.setTableScrollDOM("elTable");
  },
  activated() {
    this.setScrollRecord();
  },
  methods: {
    //查询一级分类
    async getFirstClass() {
      try {
        this.selectLoading = true;
        let res = await getFirstClassList();
        this.firstClassList = this.getFreezeResponse(res, {
          path: "data"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.selectLoading = false;
      }
    },
    //查询二级分类
    async getSecondClass(equipClassId, type) {
      try {
        this.selectLoading = true;
        let res = await getSecondClassList({ equipClassId });
        if (type == 1) {
          this.dialogSecondClassList = this.getFreezeResponse(res, {
            path: "data"
          });
        } else {
          this.secondClassList = this.getFreezeResponse(res, {
            path: "data"
          });
        }
      } catch (error) {
        printError(error);
      } finally {
        this.selectLoading = false;
      }
    },
    // 表格勾选时间
    handleSelectionChange(columns) {
      this.selColumnId = columns.map(item => {
        return Object.assign(
          {},
          {
            id: item.id,
            versionNumber: item.versionNumber
          }
        );
      });
    },
    //启用、禁用
    changeStatus(type) {
      let tip, title;
      if (type == 1) {
        tip = "是否确认启用场景安装位置";
        title = "启用场景安装位置";
      } else {
        tip = "是否确认禁用场景安装位置";
        title = "禁用场景安装位置";
      }
      this.$confirm(tip, title, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          if (type == 1) {
            this.enableErrorReq();
          } else {
            this.disableErrorReq();
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    //启用请求
    async enableErrorReq() {
      try {
        this.loading = true;
        let res = await enableEquInsPlaceList({ idList: this.selColumnId });
        if (res.code == "OK") {
          this.$message.success("启用成功");
          this.selColumnId = [];
          this.$refs.elTable.clearSelection();
          this.queryList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //禁用请求
    async disableErrorReq() {
      try {
        this.loading = true;
        let res = await disableEquInsPlaceList({ idList: this.selColumnId });
        if (res.code == "OK") {
          this.$message.success("禁用成功");
          this.selColumnId = [];
          this.$refs.elTable.clearSelection();
          this.queryList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    // 获取页面操作权限
    getOperateList() {
      // 页面按钮权限
      let operates = [
        {
          label: "编辑",
          id: 1
        }
      ];
      return operates;
    },
    // 操作--更多
    handleOperate(val, row) {
      if (val.id == 1) {
        this.addPositionVisible = true;
        this.isAdd = false;
        this.addPlaceForm.firstClassId = _.cloneDeep(row.firstClassId);
        this.addPlaceForm.secondClassId = _.cloneDeep(row.secondClassId);
        this.addPlaceForm.insPlace = _.cloneDeep(row.insPlace);
        this.addPlaceForm.remark = _.cloneDeep(row.remark);
        this.addPlaceForm.id = _.cloneDeep(row.id);
        this.addPlaceForm.versionNumber = _.cloneDeep(row.versionNumber);
      }
    },
    /*********新增**********/
    //添加安装位置
    handleAdd() {
      this.addPositionVisible = true;
      this.isAdd = true;
    },
    handleUpdate() {
      this.$refs.addPlaceForm.validate(valid => {
        if (valid) {
          if (this.isAdd) {
            this.createReq();
          } else {
            this.updateReq();
          }
        }
      });
    },
    //新增故障原因
    async createReq() {
      try {
        this.selectLoading = true;
        let res = await createPlace(this.addPlaceForm);
        if (res.code == "OK") {
          this.$message.success("创建成功");
          this.handleClose();
          this.queryList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.selectLoading = false;
      }
    },
    //修改故障原因
    async updateReq() {
      try {
        this.selectLoading = true;
        let res = await updatePlace(this.addPlaceForm);
        if (res.code == "OK") {
          this.$message.success("修改成功");
          this.handleClose();
          this.queryList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.selectLoading = false;
      }
    },
    //关闭弹框
    handleClose() {
      this.addPlaceForm = {
        firstClassId: "",
        insPlace: "",
        secondClassId: "",
        remark: ""
      };
      this.$refs.addPlaceForm.resetFields();
      this.addPositionVisible = false;
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/.custom-dialog {
  .el-dialog__body {
    padding: 20px 18px 2px 18px;
    // .el-input {
    //   width: 350px;
    // }
  }
}
</style>
